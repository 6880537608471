
.DeviceListItemContainer{

}

.DeviceListItem2Container{
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-top:8px;
    padding-left:8px;
    padding-right:8px;
    padding-bottom:8px;
    border-radius: 8px;
}

.DeviceListItemContainer{
    display: flex;
    flex-direction: row;
}

.deviceImageContainer{
    flex: 15%;
    height:50px;
}

.deviceImageContainer{
    width: 15%;
}

.deviceImage{
    width:auto;
    height:50px;
}

.deviceNameStateContainer{
    flex:45%
}

.deviceName{
    width:100%
}

.deviceUiStatusText{
  width:100%
}


.deviceButton{
    flex: 30%;
}



.deviceConfiguration{
    flex: 10%;
}

.deviceSelected{
    flex: 0%;
}

.deviceListSelectedArrow{
    /*background: url('./img/arrowright.svg') no-repeat;
    background-size: 50px;
    background-position: right;*/ 
}

.deviceListSelected{
    /*background-color:#F8F8F8;*/
}

div.deviceListSelected{
    font-weight: bold;
    background-color:#deeafd;
    color: darkslategrey
  }