@tailwind base;
@tailwind components;
@tailwind utilities;


body {
    background-color: white;
    overflow: scroll;
  }

phoneImage {
    width:50px;
    height:50px;

}



.backgroundBlurredAppStoreContainer {

  background: hsla(0, 0%, 100%, 0.55);
  backdrop-filter: blur(30px);
  z-index: 1;

}

div.CommandPanelContainer{
  width: 100%;
}

